<template>
  <div class="order-inquiry" :style="{height:clientHeight+'px'}">
    <img src="@/assets/images/icon_spbx_d.png" class="icon" alt="">
    <div class="content-box">
      <div class="input-wrapper">
        <img class="input-icon" src="@/assets/images/icon_ss.png" alt="">
        <input type="number" v-model="phone" placeholder="请输入手机号查询" @input="phoneInput">
      </div>
      <div class="input-wrapper" v-if="enableCaptcha">
        <img class="input-icon" src="@/assets/images/icon_lx.png" alt="">
        <input type="number" placeholder="请输入验证码" v-model="captcha">
        <button class="captcha" @click="getCaptcha" :disabled="!!countdown">
          {{ countdown ? countdown + ' S后重新获取' : '获取验证码' }}
        </button>
      </div>
    </div>
    <div class="inquiry-btn" @click="inquiry">点击查询</div>
  </div>
</template>

<script>
import {orderActivityInfo, sendSms} from "@/api";

export default {
  name: "index",
  data() {
    return {
      phone: '',
      captcha: '',
      countdown: 0,
      interval: null,
      alreadySent: false,
      clientHeight:0
    }
  },
  computed: {
    enableCaptcha() {
      return this.$store.state.config.is_sms === 1
    }
  },
  mounted() {
    this.clientHeight=document.body.clientHeight
  },
  methods: {
    phoneInput() {
      this.phone = this.phone.replace(/\D/g, '').slice(0, 11)
    },
    getCaptcha() {
      if (!/^1[3-9]\d{9}$/.test(this.phone)) {
        this.$toast('请输入正确手机号码');
        return
      }
      this.alreadySent = true
      this.countdown = 60;
      this.interval = setInterval(() => {
        this.countdown = this.countdown - 1
        if (this.countdown <= 0) {
          clearInterval(this.interval)
        }
      }, 1000)

      sendSms({phone: this.phone}).then(res => {
        this.$toast(res && res.msg || '发送验证码成功，请注意查收')
      }).catch(err => {
        this.$toast(err && err.msg || '发送验证码失败')
      })
    },
    inquiry() {
      if (!this.phone) {
        this.$toast('请输入手机号')
        return;
      }
      if (!this.captcha && this.enableCaptcha) {
        this.$toast('请输入验证码')
        return;
      }
      let params={
        phone:this.phone,
        pass_id:this.$store.state.config.pass_id
      }
      if (this.enableCaptcha) {
        params.code=this.captcha
      }
      orderActivityInfo(params).then(res=>{
        if (res.data) {
          // this.$store.commit('setOrderDetail',res.data);
          this.$store.commit('setOrderList',res.data);
          this.$router.push({
            path: 'orderList'
          })
        }else {
          this.$toast(res && res.msg || '未找到订单信息')
        }
      }).catch(err => {
        this.$toast(err && err.msg || '查询失败')
        console.error(err)
      })

    }
  }
}
</script>

<style scoped lang="less">
.order-inquiry {
  width: 100vw;
  height: 100vh;
  background-color: lighten(@base-color, 33%);
  position: relative;
}

.content-box {
  width: calc(100vw - 80px);
  //height: 350px;
  background: #FFFFFF;
  border-radius: 30px;
  box-shadow: 0 0 15px lighten(@base-color, 25%);
  position: absolute;
  top: 470px;
  left: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px 0;
}

.input-wrapper {
  display: flex;
  width: calc(100vw - 150px);
  height: 80px;
  background: #F8F8F8;
  border-radius: 40px;
  align-items: center;
  padding-left: 34px;
  position: relative;

  &:nth-child(2) {
    margin-top: 44px;
  }

  input {
    width: 280px;
    background-color: #F8F8F8;
    font-size: 28px;
    font-weight: 500;
    color: #666666;

    &::-webkit-input-placeholder {
      font-size: 28px;
      font-weight: 400;
      color: #999999;
    }
  }

  .captcha {
    position: absolute;
    right: 0;
    top: 0;
    width: 200px;
    height: 80px;
    background: @base-color;
    border: 1px solid @base-color;
    border-radius: 40px;
    line-height: 80px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;

    &[disabled] {
      background: lighten(@base-color, 15%);
      border: 1px solid lighten(@base-color, 15%);
    }
  }
}

.input-icon {
  width: 40px;
  height: 40px;
  margin-right: 28px;
}

.inquiry-btn {
  width: calc(100vw - 150px);
  height: 88px;
  line-height: 88px;
  text-align: center;
  background: linear-gradient(90deg, @base-color 0%, lighten(@base-color, 5%) 100%);
  box-shadow: 0 6px 15px 1px lighten(@base-color, 25%);
  border-radius: 44px;
  font-size: 30px;
  font-weight: bold;
  color: #FFFFFF;
  position: absolute;
  bottom: 60px;
  left: 75px;
}

.icon {
  width: 286px;
  height: 286px;
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
